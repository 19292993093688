<template>
  <section class="w-100 quiz-v2">
    <template v-if="question && !allQuestionsHasAnswer">
      <header class="w-100 d-flex justify-content-between">
        <h1 class="question-title">
          {{ question.text || "" }}
        </h1>
        <span class="question-count"
          >{{ current }} {{ $t("general.of") }} {{ questions.length }}</span
        >
      </header>
      <section
        class="option w-100 d-flex align-items-center"
        v-for="(option, index) in question.answers"
        :key="index"
        :has-error="
          question.my_answer &&
          question.my_answer.id === option.id &&
          !option.is_correct
        "
        :is-correct="question.my_answer && !!option.is_correct"
        @click="setAnswer(option)"
      >
        <b-form-radio
          @click.prevent.stop=""
          class="custom-form-radio"
          name="radio"
          size="lg"
          :class="{
            'has-error':
              question.my_answer &&
              question.my_answer.id === option.id &&
              !option.is_correct,
            'is-correct': question.my_answer && !!option.is_correct,
          }"
          v-model="selected"
          :value="option.id"
          :checked="option.id === selected"
          disabled
        >
        </b-form-radio>
        <span class="option-text">
          {{ option.text || "" }}
        </span>
      </section>
    </template>
    <section v-else class="result w-100">
      <header class="w-100 d-flex justify-content-between">
        <h1 class="question-title">{{ $t("general.final_result") }}</h1>
      </header>
      <section class="section-result success" v-if="successQuestions.length">
        <section class="questions-count">
          <h1>
            {{
              successQuestions.length < 10
                ? `0${successQuestions.length}`
                : successQuestions.length
            }}
          </h1>
          <p>Acertos 🎉</p>
        </section>
        <section class="questions">
          <section
            class="question"
            v-for="(quest, index) in successQuestions"
            :key="index"
          >
            <p class="title">
              {{ $t("general.question") }} {{ index + 1 < 10 ? `0${index + 1}` : index + 1 }}
            </p>
            <p class="text">{{ quest.text }}</p>
            <p class="answer">{{ quest.my_answer.text }}</p>
          </section>
        </section>
      </section>
      <section class="section-result errors" v-if="errorsQuestions.length">
        <section class="questions-count">
          <h1>
            {{
              errorsQuestions.length < 10
                ? `0${errorsQuestions.length}`
                : errorsQuestions.length
            }}
          </h1>
          <p>Erros 😔</p>
        </section>
        <section class="questions">
          <section
            class="question"
            v-for="(quest, index) in errorsQuestions"
            :key="index"
          >
            <p class="title">
              {{ $t("general.question") }} {{ index + 1 < 10 ? `0${index + 1}` : index + 1 }}
            </p>
            <p class="text">{{ quest.text }}</p>
            <p class="answer">
              {{ quest.answers.filter((item) => item.is_correct).pop().text }}
            </p>
          </section>
        </section>
      </section>
    </section>
  </section>
</template>

<script>
// Services
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  computed: {
    questionId() {
      return this.question.id;
    },
    allQuestionsHasAnswer() {
      return this.questions.every((item) => !!item.my_answer);
    },
    successQuestions() {
      return this.questions.filter((item) => !!item?.my_answer?.is_correct);
    },
    errorsQuestions() {
      return this.questions.filter((item) => !item?.my_answer?.is_correct);
    },
  },
  data: () => ({
    questions: [],
    selected: null,
    current: 1,
    question: null,
    result: {
      success: [],
      error: [],
    },
  }),
  methods: {
    setCurrentQuestion(index) {
      if (this.questions[index - 1]?.my_answer) {
        this.setCurrentQuestion(index + 1);
        return;
      }
      this.question = this.questions[index - 1];
      this.current = index;
    },
    async setAnswer(answer) {
      if (this.question.my_answer) return false;
      this.question.my_answer = answer;
      this.selected = answer.id;
      let idCourse = this.$route.params.curso;
      let lessonAtual = this.$route.params.aula;
      let idModule = this.$route.params.modulo;

      await serviceCourse
        .postID2({
          id: {
            id: `${idCourse}/module/${idModule}/lesson/${lessonAtual}/question/${this.questionId}/answer/student`,
          },
          data: {
            answer_id: this.selected,
            question_id: this.questionId,
          },
        })
        .then(async () => {
          if (this.current < this.questions.length) {
            setTimeout(() => {
              this.setCurrentQuestion(this.current + 1);
            }, 3000);
          } else {
            this.$emit("quizCompleted");
            await this.getQuestions();
          }
        });
    },
    async getQuestions() {
      let idCourse = this.$route.params.curso;
      let lessonAtual = this.$route.params.aula;
      let idModule = this.$route.params.modulo;

      await serviceCourse
        .get(`${idCourse}/module/${idModule}/lesson/${lessonAtual}`)
        .then((res) => {
          this.questions = res.questions;
          if (this.allQuestionsHasAnswer) {
            this.$emit("quizCompleted");
          }
        });
    },
  },
  async mounted() {
    await this.getQuestions();
    this.setCurrentQuestion(1);
  },
};
</script>

<style lang="scss" scoped>
.quiz-v2 {
  color: var(--text-color);
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;

  header {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    header {
      flex-direction: row;
    }
  }

  @media (min-width: 1200px) {
    border: var(--border-quiz);
    border-radius: 20px;
    padding: 40px 35px;
  }

  .question-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;
    max-width: 450px;
  }

  .question-count {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9a9a9a;
  }
  .option {
    padding: 15px 25px;
    border-radius: 50px;
    background: var(--option);
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;

    &-text {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 150%;
      color: var(--contrast-bg);
    }

    &[has-error] {
      background: rgba(211, 20, 8, 0.1);
      .option-text {
        color: #d31408;
      }
    }

    &[is-correct] {
      background: rgba(1, 202, 166, 0.1);
      .option-text {
        color: #01caa6;
      }
    }
  }

  .result {
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    display: flex;
    flex-direction: column;
    gap: 30px;
    .section-result {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 25px 30px;
      border-radius: 20px;
      gap: 35px;

      @media (min-width: 1200px) {
        flex-direction: row;
      }

      .questions-count {
        min-width: 85px;
      }

      .questions {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .question {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .title {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: var(--text-color);
        }

        .text {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: var(--text-color);
        }

        .answer {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }
      }

      &.success {
        background: rgba(1, 202, 166, 0.1);
        color: #01caa6;
      }
      &.errors {
        background: rgba(211, 20, 8, 0.1);
        color: #d31408;
      }
    }
  }
}
</style>